<template>
    <div class="item-bg">
      <nav-bar></nav-bar>
      <div class="img-spacing">
        <img v-for="item in imgs" v-lazy="item" :key="item" class="img"/>
      </div>
      <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import { useRoute } from 'vue-router'
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer
    },
  
  setup() { 
    const route = useRoute();
    const { id } = route.query
    let imgs=[]
    for (let i = 0; i < 6; i++) {
      imgs.push(require(`../assets/images/${id}/item${i}.jpg`)) 
    }
    const useData = reactive({})
    return {
      imgs,
      ...toRefs(useData),
      id
    }
  }
}
  </script>
  <style scoped>
  .item-bg{
    background: #000;
  }
  .img-spacing{
    margin-top: 92px;
  }
</style>
  